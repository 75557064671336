@keyframes glow {
  from {
    box-shadow: 0px 0px 15px -5px #888;
  }

  to {
    box-shadow: 2px 2px 25px -3px #888;
  }
}

img:hover {
  animation: glow 0.4s forwards;
}


img {
  border-radius: "10px";
  object-position: "50% 20%";
}
