.App2 {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 100px;
  justify-content: center;
}

.column1 {
  padding: 0 none;
  justify-content: center;
}

.name {
  font-weight: 900;
  font-size: 25px;
  justify-content: center;
  margin-top: 25px;
  padding-bottom: 20px;
}

.name:hover {
  opacity: 0.6;
}

.url {
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.icon1 {
  font-size: 30px;
  padding-left: 10px;
}

.fa.fa-github {
  color: black;
}

.icon1:hover {
  opacity: 0.6;
}

.Description {
  opacity: 1;
  justify-content: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  line-height: 20px;
  font-size: 15px;
  padding: 20px;
}

.by1 {
  margin: 5px;
  padding: 15px 5px;
  font-weight: bold;
  justify-content: center;
  color: white;
}


.madeby {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: dodgerblue;
}

.box {
  width: 550px;
  background: white;
  margin: 30px;
}

.effect82 {
  position: relative;
  box-shadow: 0 1px 18px -4px #888888;
}

.effect82:before,
.effect82:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 1px 18px -4px #888888;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
@media (max-width:600px){
  .box{
    width:100%;
    margin:10px 0 10px 0;
  }
}