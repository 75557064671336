/*PEN STYLES*/
* {
  box-sizing: border-box;
}

.blog-card {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin: 1rem auto;
  margin-bottom: 50px;
  box-shadow: 0px 0px 15px -5px #888;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
}

.blog-card:hover {
  animation: glow 0.4s forwards;
}

@keyframes glow {
  from {
    box-shadow: 0px 0px 15px -5px #888;
  }

  to {
    box-shadow: 2px 2px 25px -3px #888;
  }
}

.blog-card a {
  color: inherit;
}
/* .blog-card a:hover {
    color: rgba(0,0,0,0.88);
  } */
/* .blog-card:hover .photo {
    transform: scale(1.3) rotate(3deg);
  } */
.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}
.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.blog-card .details {
  position: relative;
  /* top: 0;
    bottom: 0; */
  /* left: -100%; */
  margin: auto;
  /* transition: left 0.2s; */
  /* background: rgba(0, 0, 0, .6); */
  color: #000;
  /* padding: 10px; */
  /* width: 100%; */
  font-size: 0.9rem;
}
.blog-card .details a {
  text-decoration: none;
}
/* .blog-card .details ul li {
    display: inline-block;
  } */
.blog-card .details .author:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f007";
}
.blog-card .details .date:before {
  font-family: FontAwesome;
  /* margin-right: 10px; */
  content: "\f133";
}

.blog-card .details .tags li {
  margin-right: 2px;
}
.blog-card .details .tags li:first-child {
  margin-left: -4px;
}
.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}
.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}

.blog-card .description h2:before {
  font-family: FontAwesome;
  content: "\f02b";
  margin-left: 5px;
}

.blog-card .description h1 {
  /* padding-left:20px; */
  line-height: 1;
  margin: 10px 10px 10px 0;
  font-size: 2.2rem;
  font-weight: bold;
}
.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  color: #474747;
  margin-top: 5px;
}
.blog-card .description .read-more a:after {
  content: "\f061";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}
.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}
.blog-card p:first-of-type {
  padding-bottom: 50px;
  padding-top: 30px;
  color: #1a1a1a;
  width: 95%;
}
.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  /* background: #e7433a; */
  width: 95%;
  top: -0.25rem;
  border-radius: 3px;
}
/* .blog-card:hover .details {
    left: 0%;
  } */
@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 70%;
  }
  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .blog-card.alt {
    flex-direction: row-reverse;
  }
  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }
  /* .blog-card.alt .details {
      padding-left: 25px;
    } */
}

.button-tags {
  font-size: 11px;
  border-radius: 2px;
  padding: 4px;
  margin: 3px;
  line-height: 30px;
  border: 1px rgba(231, 152, 34, 0.65) solid;
  color: rgba(231, 152, 34, 0.65);
}

.button-tags:hover {
  border: 1px rgb(231, 152, 34) solid;
  color: rgb(231, 152, 34);
  cursor: default;
}

.fab:hover {
  animation-name: myRotate;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes myRotate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.icons-font {
  color: #474747;
  margin-bottom: 0;
}

.icons-font-padding {
  padding: 20px;
}
.wrapper {
  display: flex;
  justify-content: center;
}

.cta {
  display: flex;
  padding: 10px 45px;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 18px;
  color: white;
  background: #e6433a;
  transition: 1s;
  transform: skewX(-15deg);
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #f1f1f1;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

span {
  transform: skewX(15deg);
}

span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #38b9fa;
  }
  100% {
    fill: white;
  }
}
